import { List, X } from "phosphor-react";
import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [background, setBackground] = React.useState("transparent");
  const [hidden, setHidden] = React.useState(false);

  React.useState(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > window.innerHeight * 0.7) {
        setBackground("#010101");
        setHidden(true);
      } else {
        setBackground("transparent");
        setHidden(false);
      }
    });
  });
  return (
    <nav
      style={{
        background,
        transform: hidden ? "translateY(-100%)" : "translateY(0)",
      }}
    >
      <Link to='/' className='text-2xl font-medium'>
        <img
          src={require("../assets/logos/logo.png")}
          alt='Afro tape logo'
          className='w-14'
        />
      </Link>
      <div className='space-x-16 hidden lg:flex'>
        <Link className='underline-link' to='/about'>
          About Us
        </Link>
        <Link className='underline-link' to='/team'>
          Our Team
        </Link>
        <Link className='underline-link' to='/contact'>
          Contact
        </Link>
        <Link className='underline-link' to='/donate'>
          Donate
        </Link>
      </div>
      <List
        color='white'
        size={28}
        className='lg:hidden'
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <div
        className={`flex flex-col space-y-2 fixed top-0 right-0 transition-all duration-300 z-[99999] p-10 h-screen w-screen lg:w-[50vw] bg-black/90`}
        style={{
          transform: isOpen ? "translateX(0)" : "translateX(100%)",
        }}
      >
        <X
          size={28}
          color='white'
          onClick={() => {
            setIsOpen(false);
          }}
          className='cursor-pointer self-end'
        />
        <div className='flex flex-col space-y-3 py-10 text-2xl'>
          <div className='w-full p-4'>
            <Link
              to='/'
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Home
            </Link>
          </div>
          <div className='w-full p-4'>
            <Link
              to='/about'
              onClick={() => {
                setIsOpen(false);
              }}
            >
              About
            </Link>
          </div>
          <div className='w-full p-4'>
            <Link
              to='/about'
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Our Team
            </Link>
          </div>
          <div className='w-full p-4'>
            <Link
              to='/donate'
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Donate
            </Link>
          </div>
          <div className='w-full p-4'>
            <Link
              to='/contact'
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Contact
            </Link>
          </div>
        </div>
        <div className='p-4 text-lg'>
          <div className='flex flex-col space-y-6 text-gray-200'>
            <div>Afro Tape Music Foundation | Afro Tape</div>
            <div>
              <span>
                © {new Date().getFullYear()} Afro Tape Music Foundation
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
