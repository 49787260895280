import { ArrowRight } from "phosphor-react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Fade from "react-reveal/Fade";
import React from "react";
import Services from "../components/Services";

const Home = () => {
  React.useEffect(() => {
    window.scroll({
      behavior: "smooth",
      left: 0,
      top: 0,
    });
  }, []);

  return (
    <div>
      <Header />
      <main>
        <section className='flex flex-col items-center text-center justify-center px-4 py-20 lg:p-20 gap-10'>
          <Fade bottom cascade>
            <div className='text-center text-2xl lg:text-4xl '>
              Discovering and Empowering Musicians.
            </div>
          </Fade>
          <div className='slide-left flex flex-col gap-5 text-gray-600 text-lg max-w-4xl'>
            <span>
              AFRO-TAPE MUSIC FOUNDATION is dedicated to making a socio-economic
              impact in the lives of out-of-school-children and youths in local
              communities through music.
            </span>
            <span>
              We aim to foster creativity, develop skill and talent while
              supporting the entrepreneurship growth of our beneficiaries.
            </span>
          </div>
          <a
            href='https://docs.google.com/forms/d/e/1FAIpQLSfbza_9Aunb3lbajUulIBli2tTV_1MT7gJgynytyk1ppxyuTg/viewform'
            className='py-4 px-10 transform hover:scale-105 transition-all duration-300 flex space-x-4 items-center bg-zinc-800 w-max text-white group rounded-full'
          >
            <span>Join Us</span>
            <ArrowRight
              size={20}
              weight='bold'
              className='transform transition-all duration-300 group-hover:tranzinc-x-2'
            />
          </a>
        </section>
        <Services />
        <section className='flex flex-col lg:flex-row  items-center justify-center px-4 py-20 lg:p-20 relative gap-10 text-white'>
          <div className='flex flex-col gap-10 flex-1 order-2 lg:order-1 w-full h-full'>
            <div className='flex flex-col gap-2'>
              <div className='text-2xl lg:text-6xl'>
                Help us create more entrepreneurs
              </div>
              <p className='text-zinc-400'>
                Your contribution helps us empower our targets
              </p>
            </div>
            <Link
              to='/donate'
              className='py-4 px-10 transform hover:scale-105 transition-all duration-300 flex space-x-4 items-center bg-zinc-50 w-max text-black font-semibold group rounded-full'
            >
              <span>Donate</span>
            </Link>
          </div>
          <img
            src={require("../assets/pictures/IMG_0102.WEBP")}
            alt=''
            className='aspect-square object-cover hover:scale-105 transition duration-500 absolute w-full h-full -z-[1] filter brightness-[.2]'
          />

          {/* <div className='flex gap-10 items-center order-1 flex-1'>
            <div className='text-white'>
              <PieChart
                skipAnimation
                sx={{
                  color: "white",
                }}
                slotProps={{
                  legend: {
                    classes: "text-white",
                  },
                }}
                series={[
                  {
                    data: [
                      { id: 0, value: 10, label: "Raised" },
                      { id: 1, value: 70, label: "Raising" },
                      { id: 2, value: 20, label: "Donations" },
                    ],
                    highlightScope: { faded: "global", highlighted: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                    innerRadius: 80,
                    outerRadius: 130,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    startAngle: 0,
                    endAngle: 360,
                    cx: 150,
                    cy: 150,
                  },
                ]}
                width={260 + 160 + 40}
                height={310}
              />
            </div>
          </div> */}
        </section>
        <section className='flex flex-col items-center text-center justify-center px-4 py-20 lg:p-20 gap-10 bg-zinc-900 text-white'>
          <div className='flex flex-col justify-center items-center gap-2'>
            <Fade bottom cascade>
              <div className='text-center text-2xl lg:text-4xl '>
                Subscribe to our Newsletter
              </div>
            </Fade>
            <p className='text-zinc-400'>
              Sign Up with your email to recieve news and updates.
            </p>
          </div>
          <form className='slide-left flex flex-col gap-5 text-gray-600 text-lg max-w-xl w-full '>
            <input
              type='email'
              placeholder='your@email.com'
              className='p-4 w-full rounded-xl bg-zinc-700 text-white'
            />
            <Link
              to='/about'
              className='py-4 px-10 w-full rounded-full bg-black justify-center transform hover:scale-105 transition-all duration-300 flex space-x-4 items-center  text-white group'
            >
              <span>Sign Up</span>
            </Link>
          </form>
        </section>
      </main>
    </div>
  );
};

export default Home;
