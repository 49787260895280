import {
  Envelope,
  InstagramLogo,
  LinkedinLogo,
  TwitterLogo,
  YoutubeLogo,
} from "phosphor-react";

const Footer = () => {
  return (
    <footer className='relative'>
      <div className='flex flex-col justify-center gap-5'>
        <div className='text-4xl font-medium'>You belong here.</div>
        <div className='flex flex-col gap-6 text-gray-200'>
          <div>AFRO-TAPE MUSIC FOUNDATION</div>
          <div className='flex flex-col gap-5'>
            <span>© {new Date().getFullYear()} All Rights Reserved.</span>
            <div className='flex flex-col gap-2 font-medium'>
              <div className='font-semibold'>Hours: </div>
              <div>Monday–Friday 10am–6pm</div>
            </div>
            <div className='flex flex-wrap font-medium'>
              <span>Lagos, Nigeria</span>
            </div>
            <div className='flex flex-wrap'>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col justify-center space-y-3'>
        <div className='flex space-x-2 w-3/4 items-center py-3 border-b border-b-white'>
          <Envelope size={20} className='text-blue-500' weight='bold' />
          <span>Contact Us</span>
        </div>
        <a
          href='mailto:info@afro-tape.com'
          className='underline-link w-max font-medium'
        >
          info@afro-tape.org
        </a>
        <a
          href='tel:+2348064165455'
          className='underline-link w-max font-medium'
        >
          +234 806 416-5455
        </a>
        <a
          target='_blank'
          href='https://instagram.com/afro_tape'
          className='w-max font-medium flex space-x-2 items-center'
          rel='noreferrer'
        >
          <InstagramLogo size={20} weight='duotone' /> <span>Instagram</span>
        </a>
        <a
          target='_blank'
          href='https://x.com/afro_tape'
          className='w-max font-medium flex space-x-2 items-center'
          rel='noreferrer'
        >
          <TwitterLogo size={20} weight='duotone' /> <span>Twitter (X)</span>
        </a>
        <a
          target='_blank'
          href='https://youtube.com/@Afro-tape'
          className='w-max font-medium flex space-x-2 items-center'
          rel='noreferrer'
        >
          <YoutubeLogo size={20} weight='duotone' />{" "}
          <span>Youtube (7.5k subscribers)</span>
        </a>
        <a
          target='_blank'
          href='https://www.linkedin.com/company/afro-tape-music-foundation/'
          className='w-max font-medium flex space-x-2 items-center'
          rel='noreferrer'
        >
          <LinkedinLogo size={20} weight='duotone' /> <span>LinkedIn</span>
        </a>
      </div>
      {/* <p className='absolute right-0 bottom-0 m-4 text-xs text-gray-500 hover:underline decoration-zinc-400 p-2'>
        <a href='https://abidemi-harry.netlify.app?utm-source=afro-tape'>
          Designed by Ope Abidemi
        </a>
      </p> */}
    </footer>
  );
};

export default Footer;
