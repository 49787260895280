import React, { useEffect, useState } from "react";
import useTeams from "../hooks/useTeams";
import { API_URL } from "../constants";
import { ArrowRight, EnvelopeSimple } from "phosphor-react";
import { fetchImage } from "../helpers/fetch";

const Teams = () => {
  const { teamsData } = useTeams();

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const getImageUrls = async () => {
      const urls = await Promise.all(
        teamsData.data.map(async (team) => {
          const imageUrl = await fetchImage(
            `${API_URL}${team.attributes.photo.data.attributes.url}`
          );
          return imageUrl;
        })
      );
      setImageUrls(urls);
    };

    getImageUrls();
  }, [teamsData]);

  return (
    teamsData &&
    teamsData?.data && (
      <section className='grid grid-cols-1 lg:grid-cols-3 gap-10'>
        {teamsData.data.map((team, index) => {
          return (
            <div className='rounded-3xl flex flex-col gap-5'>
              <img
                src={imageUrls[index]}
                alt=''
                className='w-full aspect-[1/1.2] rounded-md bg-zinc-600/20 object-cover'
              />
              <div className='flex flex-col gap-2'>
                <div>
                  <div className='font-medium'>{team.attributes.name}</div>
                  <div className='text-base'>{team.attributes.position}</div>
                </div>
                <div className='text-sm line-clamp-3'>
                  {team.attributes.description}
                </div>
                {/* <div>
                  {team.attributes.email && (
                    <a href={`mailto:${team.attributes.email}`}>
                      <EnvelopeSimple />
                    </a>
                  )}
                </div> */}
              </div>
            </div>
          );
        })}
      </section>
    )
  );
};

export default Teams;
