const PageHeader = ({ title }) => {
  return (
    <header className='flex flex-col items-center justify-center h-[50vh] lg:h-[40vh] bg-zinc-800 text-white'>
      <img
        className='absolute inset-0 w-full h-[50vh] lg:h-[40vh] object-cover object-center'
        src={require("../assets/pictures/IMG_2.jpg")}
        alt=''
      />
      <div className='flex absolute inset-0 h-[50vh] lg:h-[40vh] items-center justify-center w-full bg-black/60 text-3xl font-medium'>
        {title}
      </div>
    </header>
  );
};

export default PageHeader;
