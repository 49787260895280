import React, { useRef } from "react";
import PageHeader from "../components/PageHeader";
import { register } from "swiper/element/bundle";
import Teams from "../components/Teams";

register();

const Team = () => {
  const swiperElRef = useRef(null);

  React.useEffect(() => {
    window.scroll({
      behavior: "smooth",
      left: 0,
      top: 0,
    });
  }, []);
  return (
    <div>
      <PageHeader title='Meet the Team' />
      <div className='px-10 py-10 lg:px-20 flex flex-col space-y-10'>
        <div className='flex flex-col space-y-6 text-xl'>
          <span className='font-medium text-3xl lg:text-5xl my-10'>
            Meet our exceptional team
          </span>
        </div>
        <div className='flex flex-col space-y-6 text-xl'>
          <Teams />
        </div>
      </div>
    </div>
  );
};

export default Team;
