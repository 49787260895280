import PageHeader from "../components/PageHeader";
import React from "react";

const Contact = () => {
  React.useEffect(() => {
    window.scroll({
      behavior: "smooth",
      left: 0,
      top: 0,
    });
  }, []);
  const [submitted, setSubmitted] = React.useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };
  return (
    <div>
      <PageHeader title='Contact Us' />
      <div className='px-10 lg:p-20'>
        <div className='grid sm:grid-cols-1 md:grid-cols-2 my-32 gap-4'>
          <h2 className='text-3xl lg:text-5xl font-medium py-6 md:px-6'>
            We exist to foster musical growth, expose new sounds of music and
            give a fantastic musical experience
          </h2>
          {submitted ? (
            <div className='bg-zinc-100 border-l-4 border-zinc-500 text-zinc-700 p-4 mb-4'>
              <p className='font-bold text-2xl'>Thank you for your message!</p>
              <p>We will get back to you as soon as possible.</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <h3 className='text-2xl font-medium'>Send us a message</h3>
              <input
                type='text'
                name='fullname'
                required
                id='fullname'
                placeholder='Your Name'
                className='border border-zinc-100 p-3 w-full rounded-md my-3'
              />
              <input
                type='email'
                name='email'
                id='email'
                required
                placeholder='Email Address'
                className='border border-zinc-100 p-3 w-full rounded-md my-3'
              />
              <textarea
                name='subject'
                id='subject'
                required
                cols='30'
                rows='5'
                placeholder='Write Something'
                className='border border-zinc-100 p-3 w-full rounded-md my-3'
              ></textarea>
              <button
                type='submit'
                className='border border-zinc-100 p-3 w-full rounded-md my-3 bg-zinc-500 text-white'
              >
                Submit
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
