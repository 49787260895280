import { useQuery } from "@tanstack/react-query";
import { API_KEY, API_URL } from "../constants";

function useTeams() {
  const { isLoading, data, error } = useQuery({
    queryKey: ["teams"],
    queryFn: () =>
      fetch(API_URL + "/api/teams?populate=*", {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
        },
      }).then((res) => res.json()),
  });

  return {
    loadingTeams: isLoading,
    teamsData: data,
    teamsError: error,
  };
}

export default useTeams;
