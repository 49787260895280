import React, { useEffect, useState } from "react";
import useServices from "../hooks/useServices";
import { API_URL } from "../constants";
import { ArrowRight } from "phosphor-react";
import { fetchImage } from "../helpers/fetch";

const Services = () => {
  const { servicesData } = useServices();

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const getImageUrls = async () => {
      const urls = await Promise.all(
        servicesData.data.map(async (service) => {
          const imageUrl = await fetchImage(
            `${API_URL}${service.attributes.cover.data.attributes.url}`
          );
          return imageUrl;
        })
      );
      setImageUrls(urls);
    };

    getImageUrls();
  }, [servicesData]);

  return (
    servicesData &&
    servicesData?.data && (
      <section className='grid grid-cols-1 md:grid-cols-3 px-4 py-20 lg:p-20 gap-10'>
        {servicesData.data.map((service, index) => {
          return (
            <div className='flex flex-col gap-5'>
              <div className='aspect-square overflow-hidden rounded-3xl'>
                <img
                  src={imageUrls[index]}
                  alt=''
                  className='aspect-square object-cover hover:scale-105 transition duration-500 w-full h-full'
                />
              </div>
              <div className='flex flex-col gap-2'>
                <div className='font-medium text-lg'>
                  {service.attributes.title}
                </div>
                <div className='text-gray-600'>
                  {service.attributes.description}
                </div>
                <a
                  href='/'
                  className='transform hover:scale-105 transition-all duration-300 flex space-x-4 items-center py-4 w-max text-black font-semibold group rounded-full'
                >
                  <span>Learn More</span>
                  <ArrowRight weight='bold' />
                </a>
              </div>
            </div>
          );
        })}
      </section>
    )
  );
};

export default Services;
