import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import "./styles/App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import About from "./pages/About";
import Contact from "./pages/Contact";
import React from "react";
import { AnimatePresence } from "framer-motion";
import Donate from "./pages/Donate";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Team from "./pages/Team";

function handleExitComplete() {
  window.scrollTo({ top: 0 });
}

const queryClient = new QueryClient();

function App() {
  const scrollRef = React.createRef();

  return (
    <QueryClientProvider client={queryClient}>
      <AnimatePresence exitBeforeEnter onExitComplete={handleExitComplete}>
        <div ref={scrollRef}>
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/donate' element={<Donate />} />
              <Route path='/team' element={<Team />} />
            </Routes>
            <Footer />
          </BrowserRouter>
          <Analytics />
          <SpeedInsights />
        </div>
      </AnimatePresence>
    </QueryClientProvider>
  );
}

export default App;
