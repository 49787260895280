import React, { useRef } from "react";
import PageHeader from "../components/PageHeader";
import { register } from "swiper/element/bundle";

register();

const About = () => {
  const swiperElRef = useRef(null);

  React.useEffect(() => {
    window.scroll({
      behavior: "smooth",
      left: 0,
      top: 0,
    });
  }, []);
  return (
    <div>
      <PageHeader title='About Us' />
      <div className='px-10 py-10 lg:px-20 flex flex-col space-y-10'>
        <div className='flex flex-col space-y-6 text-xl'>
          <span className='font-medium text-3xl lg:text-5xl my-10'>
            Passion led us here
          </span>
          <span>
            We are dedicated to fostering musical talents as well as developing
            out-of-school children and youths in disadvantaged communities with
            music.
          </span>
          <span>
            We discover and empower musical talents in disadvantaged Nigeria
            communities and Africa through rigorous search in community, online,
            and at events.
          </span>
        </div>
        <div className='flex flex-col space-y-6 text-xl'>
          <span className='font-medium text-3xl lg:text-5xl my-10'>
            Our Target and Reach
          </span>
          <div className='grid grid-cols-1 lg:grid-cols-4 gap-5'>
            <div className='flex flex-col gap-4 text-center items-center justify-center p-10 bg-zinc-900 text-white rounded-xl'>
              <div className='font-bold text-4xl'>120.8M</div>
              <div>
                Total Nigerian youth population who enjoy and listen to music
              </div>
            </div>
            <div className='flex flex-col gap-4 text-center items-center justify-center p-10 bg-zinc-900 text-white rounded-xl'>
              <div className='font-bold text-4xl'>170</div>
              <div>
                Federal, state and private universities across the nation
              </div>
            </div>
            <div className='flex flex-col gap-4 text-center items-center justify-center p-10 bg-zinc-900 text-white rounded-xl'>
              <div className='font-bold text-4xl'>200</div>
              <div>
                Micro/ micro influencers and media partners across the nation.
              </div>
            </div>
            <div className='flex flex-col gap-4 text-center items-center justify-center p-10 bg-zinc-900 text-white rounded-xl'>
              <div className='font-bold text-4xl'>90%</div>
              <div>
                Of our fans, audience and event attendees are your target
                customers.
              </div>
            </div>
            <div className='lg:col-span-2 flex flex-col gap-4 text-center items-center justify-center p-10 bg-zinc-900 text-white rounded-xl'>
              <div className='font-bold text-4xl'>20M</div>
              <div>Out of school children</div>
            </div>
            <div className='lg:col-span-2 flex flex-col gap-4 text-center items-center justify-center p-10 bg-zinc-900 text-white rounded-xl'>
              <div className='font-bold text-4xl'>54%</div>
              <div>
                Of the Nigerian population will be attending various events we
                organize in their local communities and campus
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col space-y-6 text-xl'>
          <div className='font-medium text-3xl lg:text-5xl my-10'>
            What our beneficiaries Are Saying
          </div>
          <div>
            <div className='hidden lg:block'>
              <swiper-container
                ref={swiperElRef}
                navigation='true'
                slides-per-view='3'
                pagination='true'
                space-between='20px'
                autoplay
              >
                <swiper-slide>
                  <div className='p-5 rounded-xl bg-blue-50 flex flex-col gap-5'>
                    <div className='text-base'>
                      Gratitude fills my heart as I reflect on my journey with
                      Afro Tape. Their unwavering support and empowerment have
                      been pivotal in my musical growth. I thought I had reached
                      a plateau in my expertise, but AFRO-TAPE proved otherwise.
                      Now, I'm doing so much more with my music, thanks to the
                      foundation's dedication to fostering talent.
                    </div>
                    <div className='text-sm font-medium'>—Tunde F.</div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div className='p-5 rounded-xl bg-blue-50 flex flex-col gap-5'>
                    <div className='text-base'>
                      AFRO-TAPE empowered me to do so much more with music I
                      thought I was already an expert on
                    </div>
                    <div className='text-sm font-medium'>
                      — Chijioke A. - Lagos, Nigeria
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div className='p-5 rounded-xl bg-blue-50 flex flex-col gap-5'>
                    <div className='text-base'>
                      Thanks to Afro Tape Foundations, my musical journey took
                      an incredible turn. The foundation empowered me to explore
                      dimensions of music I never thought possible, elevating my
                      skills and passion to new heights. I thought I was already
                      an expert, but AFRO-TAPE showed me there's always room for
                      growth.
                    </div>
                    <div className='text-sm font-medium'>
                      — Aisha B. - Kano, Nigeria
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div className='p-5 rounded-xl bg-blue-50 flex flex-col gap-5'>
                    <div className='text-base'>
                      Afro Tape has been a game-changer in my musical pursuits.
                      The foundation not only honed my existing talents but also
                      introduced me to new possibilities. I'm now able to do so
                      much more with music, and the experience has been nothing
                      short of transformative. Grateful for the empowerment!
                    </div>
                    <div className='text-sm font-medium'>— Leye O.</div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div className='p-5 rounded-xl bg-blue-50 flex flex-col gap-5'>
                    <div className='text-base'>
                      Being a part of Afro Tape Foundations has been a
                      transformative experience for me. The foundation's
                      commitment to nurturing musical talents is unparalleled.
                      They empowered me to push boundaries and explore uncharted
                      musical territories, making me realize there's always room
                      for improvement, even when you think you're an expert.
                    </div>
                    <div className='text-sm font-medium'>
                      - Oluwaseun F. - Ibadan, Nigeria
                    </div>
                  </div>
                </swiper-slide>
              </swiper-container>
            </div>
            <div className='lg:hidden'>
              <swiper-container
                ref={swiperElRef}
                navigation='true'
                slides-per-view='1'
                pagination='true'
                space-between='20px'
                autoplay
              >
                <swiper-slide>
                  <div className='p-5 rounded-xl bg-blue-50 flex flex-col gap-5'>
                    <div className='text-base'>
                      Gratitude fills my heart as I reflect on my journey with
                      Afro Tape. Their unwavering support and empowerment have
                      been pivotal in my musical growth. I thought I had reached
                      a plateau in my expertise, but AFRO-TAPE proved otherwise.
                      Now, I'm doing so much more with my music, thanks to the
                      foundation's dedication to fostering talent.
                    </div>
                    <div className='text-sm font-medium'>—Tunde F.</div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div className='p-5 rounded-xl bg-blue-50 flex flex-col gap-5'>
                    <div className='text-base'>
                      AFRO-TAPE empowered me to do so much more with music I
                      thought I was already an expert on
                    </div>
                    <div className='text-sm font-medium'>
                      — Chijioke A. - Lagos, Nigeria
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div className='p-5 rounded-xl bg-blue-50 flex flex-col gap-5'>
                    <div className='text-base'>
                      Thanks to Afro Tape Foundations, my musical journey took
                      an incredible turn. The foundation empowered me to explore
                      dimensions of music I never thought possible, elevating my
                      skills and passion to new heights. I thought I was already
                      an expert, but AFRO-TAPE showed me there's always room for
                      growth.
                    </div>
                    <div className='text-sm font-medium'>
                      — Aisha B. - Kano, Nigeria
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div className='p-5 rounded-xl bg-blue-50 flex flex-col gap-5'>
                    <div className='text-base'>
                      Afro Tape has been a game-changer in my musical pursuits.
                      The foundation not only honed my existing talents but also
                      introduced me to new possibilities. I'm now able to do so
                      much more with music, and the experience has been nothing
                      short of transformative. Grateful for the empowerment!
                    </div>
                    <div className='text-sm font-medium'>— Leye O.</div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div className='p-5 rounded-xl bg-blue-50 flex flex-col gap-5'>
                    <div className='text-base'>
                      Being a part of Afro Tape Foundations has been a
                      transformative experience for me. The foundation's
                      commitment to nurturing musical talents is unparalleled.
                      They empowered me to push boundaries and explore uncharted
                      musical territories, making me realize there's always room
                      for improvement, even when you think you're an expert.
                    </div>
                    <div className='text-sm font-medium'>
                      - Oluwaseun F. - Ibadan, Nigeria
                    </div>
                  </div>
                </swiper-slide>
              </swiper-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
