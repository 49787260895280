import gsap from "gsap";
import React from "react";
import img from "../assets/pictures/IMG_0083.JPG";
import { ArrowDown } from "phosphor-react";

const Header = () => {
  const heroTextRef = React.useRef(null);

  React.useEffect(() => {
    gsap.to(heroTextRef.current, {
      duration: 1,
      opacity: 1,
      y: 0,
    });

    window.addEventListener("scroll", (e) => {
      gsap.utils.toArray(".slick-scroll").forEach((el) => {
        gsap.to(el, {
          duration: 0,
          y: window.scrollY * 0.5,
        });
      });
    });
  }, []);
  return (
    <header className='relative flex flex-col'>
      <div className='relative h-[80vh] lg:h-screen text-center bg-black text-white font-medium'>
        <img
          className='absolute top-0 left-0 w-full h-full object-cover object-center'
          src={img}
          alt='backdrop'
        />
        <div className='absolute bg-black bg-opacity-80 top-0 left-0 w-full h-full flex flex-col space-y-3 justify-center items-center'>
          <div
            ref={heroTextRef}
            className='flex opacity-0 transform tranzinc-y-[100px] flex-col space-y-3 justify-center items-center px-10'
          >
            <span className='text-4xl lg:text-8xl'>
              Start your musical journey
            </span>
            <span className='text-lg lg:text-2xl'>
              Showcase your talent to the world
            </span>
          </div>
        </div>
      </div>
      <div className='absolute bottom-1/4 right-1/2 translate-x-1/2'>
        <div
          className='bg-zinc-100/5 hover:bg-zinc-100/10 p-8 rounded-full text-white cursor-pointer'
          onClick={() => {
            window.scroll({
              behavior: "smooth",
              top: window.innerHeight * 0.7,
            });
          }}
        >
          <ArrowDown size={20} className='animate-bounce' />
        </div>
      </div>
    </header>
  );
};

export default Header;
