import { ArrowRight } from "phosphor-react";
import PageHeader from "../components/PageHeader";
import React from "react";

const Donate = () => {
  React.useEffect(() => {
    window.scroll({
      behavior: "smooth",
      left: 0,
      top: 0,
    });
  }, []);
  const [submitted, setSubmitted] = React.useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };
  return (
    <div>
      <PageHeader title='Donate' />
      <div className='px-10 lg:p-20'>
        <div className='grid sm:grid-cols-1 md:grid-cols-2 my-32 gap-4'>
          <div className='py-6 md:px-6 flex flex-col gap-5'>
            <h2 className='text-3xl lg:text-5xl font-medium '>
              Your donation belong here.
            </h2>
            <p>Join a noble cause that creates talented entrepreneurs.</p>
          </div>

          {submitted ? (
            <div className='bg-zinc-100 border-l-4 border-zinc-500 text-zinc-700 p-4 mb-4'>
              <p className='font-bold text-2xl'>
                Your contribution helps us empower our targets
              </p>
              <p>We will get back to you as soon as possible.</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <h3 className='text-2xl font-medium'>Our Account Details</h3>
              <div className='w-full rounded-md my-3'>
                <div>
                  Our account details will be uploaded very soon. <br /> <br />{" "}
                  Please kindly go ahead to fill our donation form.
                </div>
              </div>

              <a
                href='https://docs.google.com/forms/d/e/1FAIpQLSdaN1BrJJnN2mouiUd-mknXhgVfzr-Bhc4CbMlMLjm9hHiYng/viewform'
                className='transform hover:scale-105 transition-all duration-300 flex space-x-4 items-center py-4 w-max text-black font-semibold group rounded-full'
              >
                <span>Fill Donor Form</span>
                <ArrowRight weight='bold' />
              </a>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Donate;
