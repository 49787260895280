import { useQuery } from "@tanstack/react-query";
import { API_KEY, API_URL } from "../constants";

function useServices() {
  const { isLoading, data, error } = useQuery({
    queryKey: ["services"],
    queryFn: () =>
      fetch(API_URL + "/api/services?populate=*", {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
        },
      }).then((res) => res.json()),
  });

  return {
    loadingServices: isLoading,
    servicesData: data,
    servicesError: error,
  };
}

export default useServices;
